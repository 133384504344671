<div class="card">
        <div class="header">
            <div class="row">
                <div class="col-sm-6">
                    <h1 style="line-height: 40px">
                        Trades
                    </h1>
                </div>
                <div class="col-sm-6">
                    <button [routerLink]="['/trade/new']"
                            class="btn btn-primary btn-border-radius waves-effect create-trade pull-right">
                        Create new Trade
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="trades"
             class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>
                        ID
                    </th>
                    <th>
                        TRADE
                    </th>
                    <th>

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let trade of trades; trackBy: trackId">
                    <td>
                        <a [routerLink]="['/trade', trade.id, 'view' ]">
                            {{trade.id}}
                        </a>
                    </td>
                    <td>
                        {{trade.trade}}
                    </td>
                    <td class="text-right"
                        style="min-width: 180px">
                        <div role="group"
                             class="bp-btn-group">
                            <button [routerLink]="['/trade', trade.id, 'view' ]"
                                    class="btn btn-info waves-effect"
                                    type="button">
                                <i class="material-icons">pageview</i>
                            </button>
                            <button [routerLink]="['/trade', trade.id, 'update']"
                                    class="btn btn-primary waves-effect"
                                    type="button">
                                <i class="material-icons">edit</i>
                            </button>
                            <button (click)="onDeleteClick(trade)"
                                    class="btn btn-danger waves-effect"
                                    type="button">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>



