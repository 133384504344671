import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { BOOK_A_DEMO_URL, BUILD_PARTNER_SUBSCRIBE_URL, DOCS_URL } from 'app/shared/constants/links.constants';
import { IAccount } from 'app/shared/model/account.model';
import { AccountService } from 'app/core/auth/account.service';
import { SERVER_BUILD_VERSION } from 'app/app.constants';
import { AccountHelperService } from "app/shared/services/account-helper.service";
import { ReviewByQsModalService } from 'app/flows/scheduler/components/review-by-qs-modal/review-by-qs-modal.service';
import { ApplicationStateService } from "app/core/application-state.service";

const BP_IMAGE_LOGO = `../../../content/images/bp-logo.svg`;

@Component({
    selector: 'bp-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['navbar.scss']
})
export class NavbarComponent implements OnInit {
    isNavbarCollapsed = true;
    version: string;

    DOCS_URL = DOCS_URL;
    BUILD_PARTNER_SUBSCRIBE_URL = BUILD_PARTNER_SUBSCRIBE_URL;
    BOOK_A_DEMO_URL = BOOK_A_DEMO_URL;

    get creationMode(): boolean {
        return this.appState.project?.id == null;
    }

    get logo(): string {
        return this.accountService.isReplaceBPLogo() ? this.accountService.getImageUrl() || BP_IMAGE_LOGO : BP_IMAGE_LOGO;
    }

    get initials(): string {
        if (this.accountService.getCompany()?.length) {
            return this.accountService.getCompany().charAt(0).toUpperCase();
        }
        if (this.accountService.getFirstName()?.length && this.accountService.getLastName()?.length) {
            return this.accountService.getFirstName()?.charAt(0).toUpperCase() + this.accountService.getLastName()?.charAt(0).toUpperCase();
        }

        return this.accountService.getLogin()?.charAt(0).toUpperCase();
    }

    get name(): string {
        if (this.accountService.getCompany()?.length) {
            return this.accountService.getCompany();
        }
        if (this.accountService.getFirstName()?.length && this.accountService.getLastName()?.length) {
            return `${this.accountService.getFirstName()} ${this.accountService.getLastName()}`;
        }

        return this.accountService.getLogin();
    }

    get authenticated(): boolean {
        return this.accountService.isAuthenticated();
    }

    get showProjectNav(): boolean {
        return this.authenticated && this.appState.shouldBeShown();
    }

    get shouldShowUpgrade(): boolean {
        return this.accountHelper.isUserNeedToBeSubscribed();
    }

    constructor(
        protected accountService: AccountService,
        private accountHelper: AccountHelperService,
        private router: Router,
        protected appState: ApplicationStateService,
        private reviewByQsModalService: ReviewByQsModalService
    ) {
        this.version = SERVER_BUILD_VERSION ? 'v' + SERVER_BUILD_VERSION : '';

        router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.isNavbarCollapsed = true;
            }
        });
    }

    ngOnInit(): void {
    }

    protected goHome(): void {
        if (this.accountService.isHomeowner()) {
            return;
        }

        this.appState.resetProject();

        this.accountService.identity().then((account: IAccount) => {
            if (_.indexOf(account.authorities, 'ROLE_SCHEDULER') > -1) {
                this.router.navigate(['/scheduler', 'projects']);
            } else if (_.indexOf(account.authorities, 'ROLE_QUOTER') > -1) {
                this.router.navigate(['/quoter', 'projects']);
            } else if (_.indexOf(account.authorities, 'ROLE_ADMIN') > -1) {
                this.router.navigate(['/admin']);
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    protected logout(): void {
        this.collapseNavbar();
        this.router.navigate(['/logout']);
    }

    protected onReviewByQSClick(): void {
        this.reviewByQsModalService.openNew();
    }

    private collapseNavbar(): void {
        this.isNavbarCollapsed = true;
    }
}
