<div class="card">
        <div class="header">
            <div class="row">
                <div class="col-lg-5 left">
                    <h1 class="build-up-label">
                        Build Ups
                    </h1>
                    <bp-archive-filter (onValueChanged)="onArchiveFilterValueChanged($event)"
                                       [defaultValue]="archiveFilterValue">
                    </bp-archive-filter>
                </div>
                <div class="col-lg-7 right">
                    <input [formControl]="searchControl"
                           class="af-input"
                           id="build-up-prefix"
                           name="build-up-prefix"
                           placeholder="Search build-up"
                           type="text"/>
                    <button [routerLink]="['/build-up/new']"
                            class="btn btn-primary btn-border-radius waves-effect create-build-up pull-right">
                        Create new Build Up
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="buildUps"
             class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr [(ascending)]="reverse"
                    [(predicate)]="predicate"
                    [callback]="reset.bind(this)"
                    bpSort>
                    <th bpSortBy="id">
                        <span>ID</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="name">
                        <span>NAME</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="category">
                        <span>CATEGORY</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="global">
                        <span>GLOBAL</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="order">
                        <span>ORDER</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th>
                        <span>TASKS</span>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let buildUp of buildUps; trackBy: trackId">
                    <td>
                        <a [routerLink]="['/build-up', buildUp.id, 'update' ]">
                            {{ buildUp.id }}
                        </a>
                    </td>
                    <td>
                        {{ buildUp.name }}
                    </td>
                    <td>
                        {{ buildUp.category?.name || '---' }}
                    </td>
                    <td>
                        {{ buildUp.global ? 'YES' : 'NO' }}
                    </td>
                    <td>
                        {{ buildUp.order }}
                    </td>
                    <td>
                        <div *ngFor="let task of (buildUp.tasks || [])">
                            <a [routerLink]="['/task', task.id, 'update' ]">
                                {{ task.id }} {{ task.task }}
                            </a>
                        </div>
                    </td>
                    <td class="text-right"
                        style="min-width: 180px">
                        <div class="bp-btn-group"
                             role="group">
                            <button [routerLink]="['/build-up', buildUp.id, 'update']"
                                    class="btn btn-primary waves-effect"
                                    type="submit">
                                <i class="material-icons">edit</i>
                            </button>
                            <button
                                [routerLink]="['/', { outlets: { popup: 'build-up/'+ buildUp.id + '/delete'} }]"
                                class="btn btn-danger waves-effect"
                                queryParamsHandling="merge"
                                replaceUrl="true"
                                type="submit">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr [hidden]="!hasMore()"
                    class="bg-white">
                    <td class="text-center"
                        colspan="1000">
                        <button (click)="loadPage(page + 1)"
                                class="btn btn-light">
                            More
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>


