import { IUser } from "app/shared/model/user.model";

export type BillingFrequency = 'MONTHLY' | 'YEARLY';
export type StripeUpgradeType = 'UPGRADE_MONTHLY' | 'SWITCH_TO_YEARLY' | 'RENEW_YEARLY' | 'UPGRADE_YEARLY';

export interface IActivityInfo {
    isOnTrial: false;
    daysLeftForTrial: number;
    exampleProjectCreationAllowed: false,
    projectCreation: {
        allowed: boolean,
        billingFrequency?: BillingFrequency;
        monthlyLimit?: number,
        currentMonthProjectCount: number
    },
    projectDeletionAllowed: true;
    showSubscriptionCompletedMessage: boolean;
    showAdditionalInfoModel: boolean;
}

export interface IThemeSetting {
    userId: number,
    property: string;
    value: string;
}

export interface IResourceCost {
    buildingMaterial: number;
    finishingMaterial: number;
    labour: number;
    total: number;
    profit?: number;
}

export interface IProjectCommentUser {
    id: number,
    firstName: string,
    lastName: string,
    email: string
}

export interface IProjectComment {
    id: number,
    comment: string,
    conversationGuid: string,
    user: IProjectCommentUser,
    timestamp: number
}

export interface ICssElement {
    id?: number;
    name?: string;
    order?: number;
    archive?: boolean;

    expanded?: boolean;
    total?: number;
}

export interface IBuildUp {
    id?: number;
    name?: string;
    global?: boolean;
    category?: IBuildUpCategory;
    categoryId?: number;
    order?: number;
    archive?: boolean;
    tasks?: {id: number, task: string}[];

    expanded?: boolean;
    total?: number;
}

export interface IBuildUpRequest {
    id?: number;
    name?: string;
    categoryId?: number;
}

export interface IBuildUpCategory {
    id?: number;
    name?: string;
    order?: number;
    archive?: boolean;
}

export interface IUnsubscriptionReason {
    id: number;
    text: string;
}

export interface IUnsubscribeRequest {
    user_id?: number;
    comment?: string;
    unsubscriptionReason?: IUnsubscriptionReason;
}

export type UpdatedScheduleTaskProperty =
    'clientSupplied'
    | 'specification'
    | 'task'
    | 'material'
    | 'unitValue'
    | 'scheduleArea'
    | 'primeMaterial'
    | 'Unknown';

export type EstimatingAddonStatus = 'PENDING' | 'DONE';

export interface IEstimatingAddon {
    id: number,
    name: string,
    cost: number,
    priceUnit: string,
    subtitle: string;
    description: string
    imageUrl: string;
    status: EstimatingAddonStatus;
}

export interface IAddServiceToProjectResponse {
    project_id: number;
    estimating_addon_id: number;
}

export type ScopeStatus = 'DRAFT' | 'SUBMITTED' | 'PROCESSING' | 'COMPLETED' | 'UPDATED';

export interface IScopeRequest {
    content: string;
    amount: number;
}

export interface IScope extends IScopeRequest {
    id: number;
    status: ScopeStatus;
}

export interface IScopesHeader {
    id: number;
    content: string;
    scopes: IScope[];
    status: ScopeStatus;
}

interface IValuationTask {
    id: number;
    task: {
        name: string;
        description: string;
    };
    stage: {
        name: string;
        description: string;
    };
    element: {
        name: string;
        description: string;
    };
    area: {
        name: string;
        description: string;
    };
    material: {
        name: string;
        description: string;
    };
    cssElement: {
        name: string;
        description: string;
    };
    qty: number;
    unit: {
        name: string;
        abbreviation: string;
    };
    total: number;
    previous: null | any; // Adjust the type accordingly
    complete: number;
    amount: number;
    remaining: number;
    status: string;
}

export interface IValuation {
    id: number;
    version: string;
    deposit: number;
    retention: number;
    depositAmount: number;
    retentionAmount: number;
    total: number;
    tasks: IValuationTask[];
}

export interface IValuationResponse {
    id: string;
    timestamp: number;
    deposit: number;
    retention: number;
    valuation: IValuation;
}

export interface IValuationRequest {
    tasks: {id: number, complete: number}[],
    projectDeposit: number;
    projectRetention: number;
    valuationDeposit: number;
    valuationRetention: number;
}

export type IKreoPropsArray = { props: IKreoProps }[];

export interface IKreoProps {
    area: number;
    perimeter: number;
    thickness: number;
    length: number;
    height: number;
    verticalArea: number;
    volume: number;
    inner_text: string;
    name: string;
}

export interface IKreoSpace {
    [key: string]: IKreoPropsArray;
}

export interface IRequestCallbackDTO {
    id?: number;
    name: string;
    mail: string;
    phone: string;
    note?: string;
}

// Discounts
export type DiscountType = 'ANNUAL_DISCOUNT';

export type DiscountStatus = 'ACTIVE' | 'INACTIVE';

export type SubscriptionTier = 'STARTER' | 'BASIC' | 'STANDARD' | 'HIGH' | 'PRO' | 'PAUSED' | 'COLLECTION_PAUSED' | 'KEEP_AS_DRAFT';

export type UserDiscountStatus = 'ACTIVE' | 'NOT_USED' | 'USED' | 'EXPIRED';

export interface ICreateDiscountOfferDTO {
    discountType: DiscountType;
    discountValue: number;
    durationDays: number;
    eligiblePlans: SubscriptionTier[];
}

export interface IAssignDiscountOfferDTO {
    userId: number;
    discountOfferId: number;
}

export interface IDiscountOffer {
    id?: number;
    discountType?: DiscountType;
    discountValue?: number;
    durationDays?: number;
    discountHeader?: string;
    discountText?: string;
    status?: DiscountStatus;
    createdAt?: string;
    eligiblePlans?: SubscriptionTier[];
}

export interface IUserDiscount {
    id?: number;
    userId?: number;
    discountOffer?: IDiscountOffer;
    activatedAt?: string;
    expiredAt?: string;
    status?: UserDiscountStatus;
}

export interface IUserDiscountDTO {
    id?: number;
    userId?: number;
    discountOfferId: number;
    discountType?: DiscountType
    discountValue?: number;
    durationDays?: number;
    discountHeader?: string;
    discountText?: string;
    activatedAt?: string;
    expiredAt?: string;
    status?: UserDiscountStatus
}

export interface IUserTracking {
    gclid: string;
    fbclid: string;
}
