import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ArchiveFilterValue } from 'app/shared/components/common/archive-filter/archive-filter.component';

export type EntityType =
    | 'TASK'
    | 'MATERIAL'
    | 'ELEMENT'
    | 'BUILD_UP'
    | 'BUILD_UP_CATEGORY'
    | 'MATERIAL_CATEGORY'
    | 'TAG'
    | 'PROFESSION'
    | 'TEMPLATE_REQUEST'
    | 'NEW_MATERIAL_REQUEST'
    | 'NEW_TASK_REQUEST'
    | 'PROJECT_QUOTER_REQUEST'
    | 'HEARD_ABOUT_US'
    | 'COMPONENT_AREA'
    | 'DISCOUNT_OFFER';

export interface IEntityQuery {
    predicate?: string;
    reverse?: boolean;
    searchValue?: string;
    archive?: ArchiveFilterValue;
}

@Injectable({ providedIn: 'root' })
export class EntityQueryStorageService {
    constructor(private webStorage: LocalStorageService) {}

    store(entityType: EntityType, value: IEntityQuery): void {
        this.webStorage.store(this.getLocalStorageKey(entityType), value);
    }

    retrieve(entityType: EntityType): IEntityQuery {
        return this.webStorage.retrieve(this.getLocalStorageKey(entityType)) || {};
    }

    private getLocalStorageKey(entityType: EntityType): string {
        return `admin_entityQuery_${entityType}`;
    }
}
