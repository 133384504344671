import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BillingFrequency, IActivityInfo, StripeUpgradeType } from 'app/shared/model/bp.model';
import {
    FreemiumModalComponent,
    FreemiumModalType
} from 'app/shared/components/common/freemium-modal/freemium-modal.component';
import { AccountService } from "app/core/auth/account.service";
import { ConfirmModalService } from "app/shared/components/common/confirm-modal/confirm-modal.service";
import { SubscriptionApi } from "app/shared/dataservices/subscription.api";
import { lastValueFrom } from "rxjs";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import {
    SuccessfullyUpdatedPlanModalService
} from "app/shared/components/common/successfully-updated-plan-modal/successfully-updated-plan-modal.service";

@Injectable({ providedIn: 'root' })
export class FreemiumModalService implements OnDestroy {
    @BlockUI() blockUI: NgBlockUI;
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal,
                private accountService: AccountService,
                private successfullyUpdatedPlanModal: SuccessfullyUpdatedPlanModalService,
                private confirmModalService: ConfirmModalService,
                private subscriptionApi: SubscriptionApi) {
    }

    verify(action: 'create_project' | 'delete_project' | 'export' | 'upload-and-auto-calculate' | 'request-template'): Promise<boolean> {
        return new Promise(resolve => {
            if (this.accountService.isAdmin()) {
                resolve(true);
                return;
            }

            this.blockUI.start('Please wait...')

            this.accountService.getActivityInfo(true)
                .finally(() => this.blockUI.stop())
                .then((activityInfo: IActivityInfo) => {
                    switch (action) {
                        case 'create_project':
                            if (!activityInfo.projectCreation.allowed) {
                                if (activityInfo.isOnTrial) {
                                    if (!activityInfo.projectCreation.monthlyLimit) {
                                        this.showModal('type_create_project');
                                    } else {
                                        this.showModal('type_create_project_monthly');
                                    }

                                    resolve(false);
                                } else {
                                    const billingFrequency = activityInfo.projectCreation.billingFrequency as BillingFrequency;

                                    this.showUpgradePlanModal(billingFrequency).then((res) => {
                                        let upgradeType: StripeUpgradeType = "UPGRADE_MONTHLY";

                                        switch (billingFrequency) {
                                            case "MONTHLY":
                                                upgradeType = res ? 'SWITCH_TO_YEARLY' : 'UPGRADE_MONTHLY';
                                                break;
                                            case "YEARLY":
                                                upgradeType = res ? 'RENEW_YEARLY' : 'UPGRADE_YEARLY';
                                                break;
                                        }

                                        this.subscriptionApi.upgrade(this.accountService.getAccountId(), upgradeType)
                                            .subscribe((upgradeRes) => {
                                                    this.accountService.identity(true);
                                                    this.successfullyUpdatedPlanModal.showModal();
                                                    resolve(true);
                                                    return;
                                                },
                                                () => {
                                                    resolve(false);
                                                });
                                    });
                                }
                            } else {
                                resolve(true);
                            }
                            break;
                        case 'delete_project':
                            if (!activityInfo.projectDeletionAllowed) {
                                this.showModal('type_delete_project');
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                            break;
                        case 'export':
                            if (activityInfo.isOnTrial) {
                                this.showModal('type_export');
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                            break;
                        case 'upload-and-auto-calculate':
                            if (activityInfo.isOnTrial) {
                                this.showModal('type_upload_and_auto_calculate');
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                            break;
                        case 'request-template':
                            if (activityInfo.isOnTrial) {
                                this.showModal('type_request_template');
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                            break;
                        default:
                            resolve(true);
                    }
                });
        })
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }

    private showModal(type: FreemiumModalType): void {
        this.ngbModalRef = this.modalService.open(FreemiumModalComponent as Component, {
            windowClass: 'bp-modal',
            backdrop: 'static',
        });

        this.ngbModalRef.componentInstance.type = type;
    }

    private showUpgradePlanModal(billingFrequency: BillingFrequency): Promise<boolean> {
        this.blockUI.start("Please wait...");

        return lastValueFrom(this.subscriptionApi.queryUpgradePlans())
            .finally(() => this.blockUI.stop())
            .then((res) => {
                let textHtml = '';
                switch (billingFrequency) {
                    case "MONTHLY": {
                        let price1 = 0;
                        const monthlyProjectLimit1 = res.body.find(b => b.prices.find(p => {
                            if (p.upgradeType === 'UPGRADE_MONTHLY') {
                                price1 = p.cost;
                                return true;
                            }
                            return false;
                        }))?.monthlyProjectLimit ?? 0;

                        let price2 = 0;
                        const monthlyProjectLimit2 = res.body.find(b => b.prices.find(p => {
                            if (p.upgradeType === 'SWITCH_TO_YEARLY') {
                                price2 = p.cost;
                                return true;
                            }
                            return false;
                        }))?.monthlyProjectLimit ?? 0;


                        textHtml = `<div class='flex flex-column gap-20'>
                                <div class='strong'>
                                    You can upgrade your monthly plan, this will increase your monthly project limit to ${monthlyProjectLimit1}pcm and increase your bill to £${price1}pcm.
                                </div>
                                <div class='strong'>
                                   Or you can move to annual, this will switch your project limit to ${monthlyProjectLimit2 * 12} per year with a 20% discount so £${price2 - (0.2 * price2)}p.a.
                                </div>
                            </div>`;
                        break;
                    }

                    case "YEARLY": {
                        let price1 = 0;
                        const monthlyProjectLimit1 = res.body.find(b => b.prices.find(p => {
                            if (p.upgradeType === 'UPGRADE_YEARLY') {
                                price1 = p.cost;
                                return true;
                            }
                            return false;
                        }))?.monthlyProjectLimit ?? 0;

                        let price2 = 0;
                        const monthlyProjectLimit2 = res.body.find(b => b.prices.find(p => {
                            if (p.upgradeType === 'RENEW_YEARLY') {
                                price2 = p.cost;
                                return true;
                            }
                            return false;
                        }))?.monthlyProjectLimit ?? 0;

                        textHtml = `<div class='flex flex-column gap-20'>
                                <div class='strong'>
                                    You can upgrade your annual plan, this will increase your annual project limit to ${monthlyProjectLimit1 * 12} per year and increase your bill by £${price1}p.a.
                                </div>
                                <div class='strong'>
                                    Or you can renew your existing plan to reset the project limit at £${price2} for the year.
                                </div>
                            </div>`;
                        break;
                    }
                }

                return this.confirmModalService.open(
                    {
                        header: billingFrequency === "MONTHLY" ? `You've hit your monthly project limit.` : `You've hit your annual project limit.`,
                        textHtml,
                        cancelButtonText: billingFrequency === "MONTHLY" ? 'Upgrade monthly plan' : 'Upgrade annual plan',
                        confirmButtonText: billingFrequency === "MONTHLY" ? 'Move to annual' : 'Renew annual plan',
                        closeLinkText: "No thanks, I'll wait"
                    }
                ).result;
            })
    }
}
