import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bp-update-schedule-task-note-modal',
    templateUrl: './edit-schedule-task-note-modal.component.html',
    styleUrls: ['edit-schedule-task-note-modal.scss']
})
export class EditScheduleTaskNoteModalComponent {
    note: string;

    constructor(private activeModal: NgbActiveModal) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    saveNote() {
        this.activeModal.close(this.note);
    }
}
