export const environment = {
    production: false,
    env: 'stage',
    server_api_url: 'https://stage-api.buildpartner.com/',
    gocardles_script: 'https://dropin-sandbox.gocardless.com/script.js',
    public_stripe_api_key: 'pk_test_51Gyf9WIpV58FRykGUZlyR6YZ4ztMYiHD5SHKlf5Tp1T4yd9DN2m7y7CygWvm4FABncMM9OQNDZpRSKXmA6OI3bzH00Kpk17myC',
    server_build_version: '6162',
    pdf_monkey_url: 'https://api.pdfmonkey.io/',
    pdf_monkey_template_id: 'bt8-bpsMFroxzcxKs7NP',
    pdf_monkey_document_template_id: 'EBA7D5FC-80D7-412C-B8CC-459E969F74D5',
    stripe_promotion_code: 'promo_1PhuaFIpV58FRykGMLVlVNZA',
    stripe_price_id: 'price_1Mh0xxIpV58FRykGxda3eVS6',
    systemUpgrades: false
};
