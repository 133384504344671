import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { IBuildUp, IBuildUpCategory } from 'app/shared/model/bp.model';
import {
    BuildUpCategoryDeletePopupComponent,
} from 'app/entities/build-up-category/delete/build-up-category-delete-dialog.component';
import { BuildUpCategoryListComponent } from 'app/entities/build-up-category/list/build-up-category-list.component';
import { BuildUpCategoryUpdateComponent } from 'app/entities/build-up-category/edit/build-up-category-update.component';
import { BuildUpCategoryApi } from 'app/shared/dataservices/build-up-category.api';

@Injectable({ providedIn: 'root' })
export class BuildUpCategoryResolve implements Resolve<IBuildUpCategory> {
    constructor(private api: BuildUpCategoryApi) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBuildUpCategory> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.api.find(id).pipe(
                filter((response: HttpResponse<IBuildUp>) => response.ok),
                map((buildUpCategory: HttpResponse<IBuildUpCategory>) => buildUpCategory.body)
            );
        }
        return of({});
    }
}

export const buildUpCategoryRoute: Routes = [
    {
        path: 'build-up-category',
        component: BuildUpCategoryListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Build Up Categories'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'build-up-category/new',
        component: BuildUpCategoryUpdateComponent,
        resolve: {
            buildUpCategory: BuildUpCategoryResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Build Up Categories'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'build-up-category/:id/update',
        component: BuildUpCategoryUpdateComponent,
        resolve: {
            buildUpCategory: BuildUpCategoryResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Build Up Categories'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const buildUpCategoryPopupRoute: Routes = [
    {
        path: 'build-up-category/:id/delete',
        component: BuildUpCategoryDeletePopupComponent,
        resolve: {
            buildUpCategory: BuildUpCategoryResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Build Up Categories'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
