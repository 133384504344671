import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SpecUpdateComponent } from './edit/spec-update.component';
import { SpecListComponent } from 'app/entities/spec/list/spec-list.component';
import { ISpecification, Specification } from 'app/shared/model/specification.model';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

@Injectable({ providedIn: 'root' })
export class SpecResolve implements Resolve<ISpecification> {
    constructor(private service: SpecificationService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ISpecification> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<ISpecification>) => response.ok),
                map((spec: HttpResponse<ISpecification>) => spec.body)
            );
        }
        return of(new Specification());
    }
}

export const specRoute: Routes = [
    {
        path: 'spec',
        component: SpecListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Specs'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'spec/new',
        component: SpecUpdateComponent,
        resolve: {
            spec: SpecResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Specs'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'spec/:id/update',
        component: SpecUpdateComponent,
        resolve: {
            spec: SpecResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Specs'
        },
        canActivate: [UserRouteAccessService]
    }
];
