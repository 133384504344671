import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { HttpResponse } from '@angular/common/http';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import * as _ from 'lodash';
import {
    ICostPlanValueColumn
} from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { IsSchedulerProjectReadOnlyService } from 'app/shared/services/is-scheduler-project-read-only.service';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import { IResourceCost } from 'app/shared/model/bp.model';
import { StatQuoterService } from 'app/shared/dataservices/stat-quoter.service';
import { ScheduleAreasHelperService } from 'app/shared/services/schedule-areas-helper.service';
import {
    getActualAreaIds,
    getActualStageIds,
    IMainViewFilterItemItem
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import {
    catchError,
    concatMap,
    filter,
    finalize,
    forkJoin,
    from,
    lastValueFrom,
    map,
    mergeMap,
    Observable,
    of,
    Subject,
    switchMap,
    take,
    takeUntil,
    takeWhile,
    tap,
    throwError,
    timer
} from 'rxjs';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { IInvitation } from 'app/shared/model/invitation.model';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ApplicationStateService } from "app/core/application-state.service";

@Injectable({ providedIn: 'root' })
export class DashboardService {

    private _stopInvitationPolling$: Subject<boolean> = new Subject<boolean>();
    private _inProgressStatusCount = 0;

    constructor(
        private appState: ApplicationStateService,
        private dashboardStore: DashboardStore,
        private isSchedulerProjectReadOnlyService: IsSchedulerProjectReadOnlyService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private scheduleAreasHelperService: ScheduleAreasHelperService,
        private statQuoterService: StatQuoterService,
        private invitationService: InvitationService,
        private alertService: BpAlertService,
        private projectApi: ProjectApi) {
    }

    public checkInvitations(): Observable<boolean> {
        let attemptCount = 0;
        const maxAttempts = 5;

        return timer(0, 1000).pipe( // Запускаем сразу и затем каждые 1000 мс
            switchMap(() => this.invitationService.query(this.appState.project.id)),
            catchError(error => {
                console.error('Error during invitation check:', error);
                return throwError(() => new Error('Invitation check failed'));
            }),
            filter(res => {
                if (!res) {
                    console.error('Invalid response from invitation service.');
                    throw new Error('Invalid response from invitation service');
                }
                return true;
            }),
            tap(res => {
                const inPCount = res.body.filter(invitation =>
                    invitation.status !== 'NOT_INVITED' &&
                    `${invitation.majorVersion}.${invitation.minorVersion}` !== this.appState.project.version
                ).length;

                if (inPCount === 0) {
                    console.log('All invitations are updated, stopping polling.');
                } else if (attemptCount >= maxAttempts) {
                    const incorrectInvitations = res.body.filter(invitation =>
                        invitation.regionBenchmarkQuoter &&
                        `${invitation.majorVersion}.${invitation.minorVersion}.` !== this.appState.project.version
                    );
                    if (incorrectInvitations.length) {
                        console.warn(`After ${attemptCount} attempts, some invitations still have incorrect versions`, incorrectInvitations);
                    }
                }
                attemptCount++;
            }),
            takeWhile(() => attemptCount < maxAttempts, true), // Остановится после 5 попыток или если inPCount === 0
            switchMap(() => attemptCount < maxAttempts ? [false] : [true]) // Завершает Observable
        );
    }

    setDefaults(): void {
        this.dashboardStore.readOnly = this.isSchedulerProjectReadOnlyService.isReadOnly(this.appState.project);
        this.dashboardStore.valueColumns = [];
        this.dashboardStore.filterState = this.mainFilterInitialStateStorageService.retrieve(this.appState.project.id, 'project_overview_scheduler');
        this.retrieveInfoIfRevertIsPossible();
    }

    init(): Observable<void> {
        return this.appState.reloadProject(true).pipe(
            take(1),
            tap(() => {
                this.dashboardStore.dashboardMode = null;
                this.dashboardStore.stageIds = null;
                this.dashboardStore.areaIds = null;
                this.dashboardStore.cssElementIds = null;
            }),
            switchMap(() => this.reloadAll()), // Дожидаемся завершения reloadAll()
            map(() => undefined) // Преобразуем в Observable<void>
        );
    }

    destroy(): void {
        this._stopInvitationPolling$.next(true);
    }

    addDefaultQuoter(): Observable<number[]> {
        return this.invitationService.addDefaultQuoters(this.appState.project.id);
    }

    async updateAllToLatestVersion(): Promise<void> {
        this.dashboardStore.invitations = await lastValueFrom(this.invitationService.query(this.appState.project.id).pipe(map(res => res.body)));
        const promises = this.getInvitationOfNotLatestVersion().map((invitation) => {
            return lastValueFrom(this.projectApi.updateQuoterToCurrentProjectVersion(this.appState.project.id, invitation.quoterId));
        });
        if (promises.length) {
            return Promise.all(promises).then(() => {
                return;
            });
        }
    }

    retrieveInfoIfRevertIsPossible(): void {
        this.projectApi.isRevertPossible(this.appState.project.id).subscribe((res: HttpResponse<boolean>) => {
            this.dashboardStore.isRevertPossible = res.body;
        });
    }

    update(): Observable<void> {
        return forkJoin([
            this.loadComparisons().pipe(tap(() => this.updateValueColumns())),
            this.updateResourceCost()
        ]).pipe(take(1), map(() => void (0)));
    }

    updateResourceCost(): Observable<void> {
        const actualAreaIds = getActualAreaIds(this.dashboardStore.filterState, this.appState.data.scheduleAreaItems);
        const actualStageIds = getActualStageIds(this.dashboardStore.filterState, this.appState.data.stageItems);

        if (!actualAreaIds?.length || !actualStageIds?.length) {
            this.dashboardStore.resourceCost = {
                buildingMaterial: 0,
                finishingMaterial: 0,
                labour: 0,
                total: 0,
                profit: 0
            }
            return of(void (0));
        }

        return this.statQuoterService.resourceCost(
            this.appState.project.id,
            this.appState.project.defaultQuoter.id,
            actualAreaIds,
            actualStageIds)
            .pipe(tap((res: HttpResponse<IResourceCost>) => {
                    this.dashboardStore.resourceCost = res.body;
                }),
                map((res) => void (0)));
    }

    updateDefaultQuoter(quoter: IComparisonQoter): void {
        this.dashboardStore.inProcessUpdatingDefaultQuoter = true;
        this.dashboardStore.valueColumns = null;

        lastValueFrom(this.projectApi
            .updateDefaultQuoter(this.appState.project.id, quoter.id))
            .finally(() => {
                this.dashboardStore.inProcessUpdatingDefaultQuoter = false;
            })
            .then(() => {
                this.projectApi.find(this.appState.project.id)
                    .subscribe((res: HttpResponse<IProject>) => {
                        this.appState.project.defaultQuoter = res.body.defaultQuoter;
                        this.update().subscribe();
                    });
            });
    }

    isQuoterDefault(quoter: IComparisonQoter): boolean {
        return quoter.id === this.appState.project.defaultQuoter?.id;
    }

    toggleItem(item: IMainViewFilterItemItem): void {
        switch (this.dashboardStore.mode) {
            case 'areas':
                if (this.dashboardStore.filterState.areaIds != null) {
                    const items = this.dashboardStore.filterState.areaIds;
                    const index = items.indexOf(item.id);
                    if (index === -1) {
                        items.push(item.id);
                    } else {
                        items.splice(index, 1);
                    }

                    if (this.dashboardStore.filterState.areaIds.length === this.appState.data.scheduleAreaItems.length) {
                        this.dashboardStore.filterState.areaIds = null;
                    }
                } else {
                    this.dashboardStore.filterState.areaIds = this.appState.data.scheduleAreaItems.filter(i => i.id !== item.id).map(i => i.id);
                }
                break;
            case 'stages':
                if (this.dashboardStore.filterState.stageIds != null) {
                    const items = this.dashboardStore.filterState.stageIds;
                    const index = items.indexOf(item.id);
                    if (index === -1) {
                        items.push(item.id);
                    } else {
                        items.splice(index, 1);
                    }

                    if (this.dashboardStore.filterState.stageIds.length === this.appState.data.stageItems.length) {
                        this.dashboardStore.filterState.stageIds = null;
                    }
                } else {
                    this.dashboardStore.filterState.stageIds = this.appState.data.stageItems.filter(i => i.id !== item.id).map(i => i.id);
                }
                break;
        }

        this.dashboardStore.filterState = this.dashboardStore.filterState;
    }

    updateItemsIds(): void {
        this.dashboardStore.areaIds = this.dashboardStore.filterState.areaIds ? this.dashboardStore.filterState.areaIds : this.appState.data.scheduleAreaItems?.map(i => i.id);
        this.dashboardStore.stageIds = this.dashboardStore.filterState.stageIds ? this.dashboardStore.filterState.stageIds : this.appState.data.stageItems?.map(i => i.id);
        this.dashboardStore.cssElementIds = this.dashboardStore.filterState.cssElementIds ? this.dashboardStore.filterState.cssElementIds : this.appState.data.cssElementItems?.map(i => i.id);
    }

    updateValueColumns(): void {
        if (!this.dashboardStore.nativeComparison) {
            return;
        }

        const valueColumns: ICostPlanValueColumn[] = [];
        for (let index = 0; index < this.dashboardStore.quoters.length; index++) {
            const quoter = this.dashboardStore.quoters[index];
            const quoterIndex = this.dashboardStore.nativeComparison.quoters.length === 1 ? 0 : this.dashboardStore.nativeComparison.quoters.findIndex(q => q.id === quoter.id);

            const valueColumn: ICostPlanValueColumn = {
                quoter: quoter,
                quoterTotal: 0,
                costPerSqm: 0,
                data: []
            };

            switch (this.dashboardStore.mode) {
                case 'stages':
                    for (const stageItem of (this.appState.data.stageItems || [])) {
                        let total = 0;

                        const stage = this.dashboardStore.nativeComparison.stageDTOs.find(stage => stage.id === stageItem.id);
                        if (stage && this.dashboardStore.stageIds.indexOf(stage.id) !== -1) {
                            stage.elementDTOs.forEach(element => {
                                element.taskDTOs.forEach(task => {
                                    if (this.dashboardStore.areaIds.indexOf(task.scheduleAreaRootId) !== -1) {
                                        total += task.totals[quoterIndex];
                                    }
                                })
                            })
                        }

                        valueColumn.data.push({ item: stageItem, total });
                    }
                    break;
                case 'areas':
                    for (const areaItem of (this.appState.data.scheduleAreaItems || [])) {
                        let total = 0;

                        this.dashboardStore.nativeComparison.stageDTOs.forEach((stage) => {
                            if (this.dashboardStore.stageIds.indexOf(stage.id) !== -1) {
                                stage.elementDTOs.forEach(element => {
                                    element.taskDTOs.forEach(task => {
                                        if (this.dashboardStore.areaIds.indexOf(task.scheduleAreaRootId) !== -1 && task.scheduleAreaRootId === areaItem.id) {
                                            total += task.totals[quoterIndex];
                                        }
                                    })
                                })
                            }
                        });

                        valueColumn.data.push({ item: areaItem, total });
                    }
                    break;
            }

            valueColumn.quoterTotal = _.sumBy(valueColumn.data, 'total');

            this.scheduleAreasHelperService.calcCostPerSqm(this.dashboardStore.areaIds, valueColumn.quoterTotal).then(res => {
                valueColumn.costPerSqm = res;
            })

            valueColumns.push(valueColumn);
        }
        this.dashboardStore.valueColumns = valueColumns;
        this.dashboardStore.inited = true;
    }

    private loadComparisons(): Observable<void> {
        this.dashboardStore.nativeComparison = {
            quoters: [],
            stageDTOs: []
        };

        const quoterIdsParam = this.dashboardStore.quoters.map(q => q.id);

        if (quoterIdsParam.length === 0) {
            return of(void (0));
        }

        this.dashboardStore.inProcessLoadingStatData = true;

        return this.projectApi.queryComparison(this.appState.project.id, quoterIdsParam).pipe(
            take(1),
            finalize(() => this.dashboardStore.inProcessLoadingStatData = false),
            tap((res: HttpResponse<IComparison>) => {
                this.dashboardStore.nativeComparison = res.body;
            }),
            map(() => void (0))
        )
    }

    private getInvitationOfNotLatestVersion(): IInvitation[] {
        return this.dashboardStore.invitations.filter(invitation => {
            return invitation.status !== 'NOT_INVITED' && !(invitation.quoterId == null || invitation.majorVersion + '.' + invitation.minorVersion == this.appState.project.version)
        });
    }

    private getVersionForQuoter(quoterId: number): string | null {
        const invitation = this.dashboardStore.invitations.find(invitation => {
            return invitation.quoterId === quoterId;
        });

        return invitation ? invitation.majorVersion + '.' + invitation.minorVersion : null;
    }

    private startInvitationsLongPolling(): void {
        timer(0, 3000)
            .pipe(concatMap(() => from(this.invitationService.query(this.appState.project.id))))
            .pipe(filter((res) => {
                const inPCount = res.body.filter(invitation => invitation.status === 'IN_PROGRESS').length;
                if (inPCount !== this._inProgressStatusCount) {
                    this.dashboardStore.isShowUpdateButtonEnabled = true;
                    this.alertService.warning("Your prices are out of sync, click 'Update Benchmarks' to ensure comparability.", 5000);
                    this._stopInvitationPolling$.next(true);
                    return true;
                }
                return false;
            }))
            .pipe(take(1))
            .pipe(takeUntil(this._stopInvitationPolling$))
            .subscribe();
    }

    private reloadAll(): Observable<void> {
        const theSameProjectIsUpdated = this.dashboardStore.projectId === this.appState.project.id;
        this.dashboardStore.projectId = this.appState.project.id;

        return forkJoin([
            this.invitationService.query(this.appState.project.id),
            this.projectApi.queryQuoters(this.appState.project.id)
        ]).pipe(
            tap(([invitationsRes, quotersRes]) => {
                this.dashboardStore.invitations = invitationsRes.body;
                this._inProgressStatusCount = this.dashboardStore.invitations.filter(inv => inv.status === 'IN_PROGRESS').length;

                if (this._inProgressStatusCount > 0) {
                    this.startInvitationsLongPolling();
                }

                const selectedArray = theSameProjectIsUpdated ?
                    this.dashboardStore.quoters.filter(q => q.selected).map(q => q.id) : null;

                this.dashboardStore.quoters = _.uniqBy(quotersRes.body, 'id')
                    .filter(q => this.getVersionForQuoter(q.id) === this.appState.project.version)
                    .map(q => {
                        q.selected = selectedArray?.length ? selectedArray.includes(q.id) :
                            (q.default && !q.company.toLowerCase().startsWith('m')) || this.appState.project.defaultQuoter?.id === q.id;
                        return q;
                    });

                this.dashboardStore.isShowBenchmarkRatesButtonEnabled =
                    !this.dashboardStore.invitations.some(i => i.regionBenchmarkQuoter === true);

                this.dashboardStore.isUpdateAllToLatestVersionButtonEnabled =
                    this.getInvitationOfNotLatestVersion().length > 0;

                this.updateItemsIds();
            }),
            mergeMap(() => this.update()), // Дожидаемся update()
            map(() => undefined) // Преобразуем в Observable<void>
        );
    }
}
