import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from 'ngx-webstorage';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { AuthInterceptor } from './blocks/interceptor/auth.interceptor';
import { AuthExpiredInterceptor } from './blocks/interceptor/auth-expired.interceptor';
import { ErrorHandlerInterceptor } from './blocks/interceptor/errorhandler.interceptor';
import { NotificationInterceptor } from './blocks/interceptor/notification.interceptor';
import { BpFrontendAppRoutingModule } from './app-routing.module';
import { BpFrontendAccountModule } from './account/account.module';
import { BpFrontendEntityModule } from './entities/entity.module';
import moment from 'moment';
import { BpMainComponent, ErrorComponent, FooterComponent, NavbarComponent, ProjectNavComponent } from './layouts';
import { BpFrontendSchedulerModule } from './flows/scheduler/scheduler.module';
import { BpFrontendQuoterModule } from './flows/quoter/quoter.module';
import { BpFrontendAdminModule } from 'app/flows/admin/admin.module';
import { createErrorHandler } from '@sentry/angular';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { BpFrontendSharedModule } from 'app/shared/shared.module';
import { BpFrontendCoreModule } from 'app/core/core.module';
import {
    BannerDialReminderComponent
} from "app/layouts/navbar/components/banner-dial-reminder/banner-dial-reminder.component";

@NgModule({
    imports: [
        BrowserModule,
        BpFrontendSharedModule.forRoot(),
        BpFrontendCoreModule,
        BpFrontendAdminModule,
        BpFrontendSchedulerModule,
        BpFrontendQuoterModule,
        BpFrontendAccountModule,
        BpFrontendEntityModule,
        BpFrontendAppRoutingModule
    ],
    declarations: [BpMainComponent, NavbarComponent, ProjectNavComponent, BannerDialReminderComponent, ErrorComponent, FooterComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false
            })
        },
        provideNgxWebstorage(
            withNgxWebstorageConfig({ prefix: 'bp', separator: '-', caseSensitive: true }),
            withLocalStorage(),
            withSessionStorage()
        )
    ],
    exports: [
        ErrorComponent
    ],
    bootstrap: [BpMainComponent]
})
export class BpFrontendAppModule {
    constructor(private dpConfig: NgbDatepickerConfig, library: FaIconLibrary) {
        this.dpConfig.minDate = { year: moment().year() - 100, month: 1, day: 1 };
        library.addIconPacks(far);
        library.addIconPacks(fas);
    }
}
