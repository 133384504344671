import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InlineSelectEditComponent),
    multi: true
};

export interface InlineSelectEditOnBlurEvent {
    oldValue: string;
    newValue: string;
}

@Component({
    selector: 'bp-inline-select-update',
    templateUrl: './inline-select-edit.component.html',
    providers: [VALUE_ACCESSOR],
    styleUrls: ['./inline-select-edit.component.scss']
})
export class InlineSelectEditComponent implements ControlValueAccessor {
    @Input() placeholder = 'Select value';
    @Input() required = true;
    @Input() idField = 'id';
    @Input() idOption = 'id';
    @Input() labelField;
    @Input() disabled = false;

    @Input() values: any[];

    @Output() onBlur = new EventEmitter<InlineSelectEditOnBlurEvent>();

    editing = false;

    public onChange: Function = Function.prototype;
    public onTouched: Function = Function.prototype;

    private preValue = '';
    private _value = '';

    get value(): string {
        return this._value;
    }

    set value(v: string) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    writeValue(value: any): void {
        this._value = value;
    }

    public registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    initialLabel(): string {
        if (!this.values) {
            return '';
        }

        const initialElement = _.find(this.values, (v: any) => v[this.idOption] === this.value);

        if (!initialElement) {
            return '';
        }

        return initialElement[this.labelField];
    }

    onBlurSelect(): void {
        this.editing = false;
        if (this._value == null) {
            this._value = this.preValue;
        }
        this.onBlur.emit({ oldValue: this.preValue, newValue: this._value });
    }

    beginEdit(value): void {
        this.preValue = value;
        this.editing = true;
    }

    trackById(index: number, item: any): void {
        return item[this.idField];
    }
}
