import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InlineEditComponent),
    multi: true
};

export interface InlineEditOnBlurEvent {
    oldValue: string;
    newValue: string;
}

@Component({
    selector: 'bp-inline-update',
    templateUrl: './inline-edit.component.html',
    providers: [VALUE_ACCESSOR],
    styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent implements ControlValueAccessor {
    @Input() label = 'Enter value here';
    @Input() required = true;
    @Input() type = 'text';
    @Input() currency = null;

    @Output() onBlur = new EventEmitter<InlineEditOnBlurEvent>();

    private _value = '';
    private preValue = '';

    editing = false;

    public onChange: Function = Function.prototype;
    public onTouched: Function = Function.prototype;

    get value(): any {
        return this._value;
    }

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    writeValue(value: any): void {
        this._value = value;
    }

    public registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    onBlurInput(): void {
        this.editing = false;
        if (this._value === '') {
            this._value = this.preValue;
        }
        this.onBlur.emit({ oldValue: this.preValue, newValue: this._value });
    }

    beginEdit(value): void {
        this.preValue = value;
        this.editing = true;
    }
}
