<div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h1 id="bp-material-category-heading">
                    Create or edit a Material Category
                </h1>
                <div>
                    <div [hidden]="!materialCategory.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="materialCategory.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly
                               type="text"/>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_name">
                            Name
                        </label>
                        <input [(ngModel)]="materialCategory.name"
                               (ngModelChange)="onNameChange()"
                               class="form-control"
                               id="field_name"
                               name="name"
                               required
                               type="text"/>
                        <div [hidden]="!(editForm.controls.name?.dirty && editForm.controls.name?.invalid)">
                            <small [hidden]="!editForm.controls.name?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>

            <div class="alert alert-warning m-t-10 ml-0"
                 *ngIf="duplicatedMaterialCategoryList?.length">
                <div>
                    Material categories with the same name already exist:
                </div>

                <a *ngFor="let mc of duplicatedMaterialCategoryList;"
                   [routerLink]="['/material-category', mc.id, 'update' ]"
                   style="display: block">
                    {{ mc.name }} (ID: {{ mc.id }})
                </a>
            </div>
        </div>
    </div>
