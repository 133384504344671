import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TaskUpdateComponent } from './edit/task-update.component';
import { TaskListComponent } from 'app/entities/task/list/task-list.component';
import { ITask, Task } from 'app/shared/model/task.model';
import { TaskApi } from 'app/shared/dataservices/task.api';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

@Injectable({ providedIn: 'root' })
export class TaskResolve implements Resolve<ITask> {
    constructor(private service: TaskApi) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITask> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<ITask>) => response.ok),
                map((task: HttpResponse<ITask>) => task.body)
            );
        }
        return of(new Task());
    }
}

export const taskRoute: Routes = [
    {
        path: 'task',
        component: TaskListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Tasks'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'task/new',
        component: TaskUpdateComponent,
        resolve: {
            task: TaskResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Tasks'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'task/:id/update',
        component: TaskUpdateComponent,
        resolve: {
            task: TaskResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'Tasks'
        },
        canActivate: [UserRouteAccessService]
    }
];
