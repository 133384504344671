import { Injectable } from '@angular/core';
import { IUserDiscountDTO } from 'app/shared/model/bp.model';
import { AccountService } from 'app/core/auth/account.service';
import { ConfirmModalService } from "app/shared/components/common/confirm-modal/confirm-modal.service";
import { BLACK_FRIDAY_ANNUAL_URL } from "app/shared/constants/links.constants";
import { SessionStorageService } from "ngx-webstorage";

const SESSION_STORAGE_KEY = 'banner-deal-shown';

@Injectable({ providedIn: 'root' })
export class BannerDealModalService {

    constructor(private sessionStorage: SessionStorageService,
                private accountService: AccountService,
                private confirmModalService: ConfirmModalService) {
    }

    showIfNeeded(): void {
        const userDiscountDTO = this.accountService.getUserDiscountDTO();
        if (!userDiscountDTO || userDiscountDTO.status !== 'ACTIVE') {
            return;
        }
        if (this.sessionStorage.retrieve(SESSION_STORAGE_KEY) === "1") {
            return;
        }

        this.sessionStorage.store(SESSION_STORAGE_KEY, "1");
        this.showModal(userDiscountDTO);
    }

    private showModal(userDiscountDTO: IUserDiscountDTO): void {
        this.confirmModalService.open(
            {
                header: userDiscountDTO.discountHeader,
                textHtml: userDiscountDTO.discountText,
                cancelButtonText: 'Close',
                confirmButtonText: 'View Plans',
                closeLinkText: "No thanks, I'll wait"
            }
        ).result.then(res => {
            if (res) {
                window.open(BLACK_FRIDAY_ANNUAL_URL, '_blank');
            }
        });
    }
}
