<div class="banner-dial-reminder" *ngIf="show && getDiscountDaysLeft() as daysLeft">
    <button (click)="closeBanner()"
            class="btn btn-secondary btn-border-radius-5 waves-effect">
        <div class="banner-title">
            {{ accountService.getUserDiscountDTO().discountValue }}% Discount Available
        </div>
        <small class="banner-subtext">
            Offer valid for {{ daysLeft }} day{{ daysLeft === 1 ? '' : 's' }}.
        </small>
    </button>
</div>

