import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { ICssElement } from 'app/shared/model/bp.model';
import { CssElementDeletePopupComponent } from 'app/entities/css-element/delete/css-element-delete-dialog.component';
import { CssElementService } from 'app/shared/dataservices/css-element.service';
import { CssElementListComponent } from "app/entities/css-element/list/css-element-list.component";
import { CssElementUpdateComponent } from "app/entities/css-element/edit/css-element-update.component";

@Injectable({ providedIn: 'root' })
export class CssElementResolve implements Resolve<ICssElement> {
    constructor(private service: CssElementService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICssElement> {
        const id = route.params['id'] ? route.params['id'] : null;
        if (id) {
            return this.service.find(id).pipe(
                filter((response: HttpResponse<ICssElement>) => response.ok),
                map((cssElement: HttpResponse<ICssElement>) => cssElement.body)
            );
        }
        return of({});
    }
}

export const cssElementRoute: Routes = [
    {
        path: 'css-element',
        component: CssElementListComponent,
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'CSS Elements'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'css-element/new',
        component: CssElementUpdateComponent,
        resolve: {
            cssElement: CssElementResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'css-elements'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'css-element/:id/update',
        component: CssElementUpdateComponent,
        resolve: {
            cssElement: CssElementResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'css-elements'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const cssElementPopupRoute: Routes = [
    {
        path: 'css-element/:id/delete',
        component: CssElementDeletePopupComponent,
        resolve: {
            cssElement: CssElementResolve
        },
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'css-elements'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
