import { Component } from '@angular/core';
import { AccountService } from "app/core/auth/account.service";
import { BLACK_FRIDAY_ANNUAL_URL } from "app/shared/constants/links.constants";

@Component({
    selector: 'app-banner-dial-reminder',
    templateUrl: './banner-dial-reminder.component.html',
    styleUrls: ['banner-dial-reminder.scss']
})
export class BannerDialReminderComponent {

    get show(): boolean {
        return true;
    }

    constructor(protected accountService: AccountService) {}

    protected getDiscountDaysLeft(): number {
        const userDiscountDTO = this.accountService.getUserDiscountDTO();
        if (!userDiscountDTO || userDiscountDTO.status !== 'ACTIVE') return 0;
        if (!userDiscountDTO.expiredAt) return 0;

        const now = new Date();
        const expireDate = new Date(userDiscountDTO.expiredAt);

        const diff = Math.ceil((expireDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
        return diff >= 0 ? diff : 0;
    }

    protected closeBanner(): void {
        window.open(BLACK_FRIDAY_ANNUAL_URL, '_blank');
    }
}
